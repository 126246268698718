var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("form", [
      !_vm.searchSchoolMenu
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "kt-head kt-head--skin-dark",
                staticStyle: { "background-image": "url(/images/bg-1.jpg)" },
              },
              [
                _c("h3", { staticClass: "kt-head__title" }, [
                  _vm._v(" Admin Menu"),
                  _c("span", { staticClass: "kt-space-15" }),
                  _c(
                    "span",
                    {
                      staticClass:
                        "btn btn-success btn-sm btn-bold btn-font-md",
                      on: { click: _vm.searchSchools },
                    },
                    [_vm._v("Search Schools")]
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "kt-grid-nav kt-grid-nav--skin-light" }, [
              _c(
                "div",
                { staticClass: "kt-grid-nav__row" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-grid-nav__item",
                      attrs: {
                        to: {
                          name: "CreateOrEditSchool",
                          params: { externalSchoolId: "new" },
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.searchSchools.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "kt-grid-nav__icon" },
                        [
                          _c("SVGIcon", {
                            attrs: {
                              name: "createAssignment",
                              "hex-color": "#fd7e14",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "kt-grid-nav__title" }, [
                        _vm._v("New School"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-grid-nav__item",
                      attrs: { to: { name: "SuperSchools" } },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.searchSchools.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "kt-grid-nav__icon" },
                        [
                          _c("SVGIcon", {
                            attrs: {
                              name: "superAdmin",
                              "hex-color": "#17a2b8",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "kt-grid-nav__title" }, [
                        _vm._v("School List"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-grid-nav__item",
                      attrs: { to: { name: "ImpersonateUsers" } },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.searchSchools.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "kt-grid-nav__icon" },
                        [
                          _c("SVGIcon", {
                            attrs: {
                              name: "impersonate",
                              "hex-color": "#6610f2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "kt-grid-nav__title" }, [
                        _vm._v("Impersonate"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kt-grid-nav__row" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-grid-nav__item",
                      attrs: { to: { name: "PublicUploads" } },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.searchSchools.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "kt-grid-nav__icon" },
                        [_c("SVGIcon", { attrs: { name: "folder" } })],
                        1
                      ),
                      _c("span", { staticClass: "kt-grid-nav__title" }, [
                        _vm._v("Public Uploads"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-grid-nav__item",
                      attrs: { to: { name: "ManageAWS" } },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.searchSchools.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "kt-grid-nav__icon" },
                        [
                          _c("SVGIcon", {
                            staticClass: "kt-svg-icon",
                            attrs: { name: "AWS" },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "kt-grid-nav__title" }, [
                        _vm._v("Cloud"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _c("div", [
            _vm._m(0),
            _c("div", { staticClass: "tab-content" }, [
              _c(
                "div",
                {
                  staticClass: "tab-pane show active",
                  attrs: {
                    id: "topbar_notifications_notifications",
                    role: "tabpanel",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll ps ps--active-y",
                      staticStyle: { height: "300px", overflow: "hidden" },
                      attrs: {
                        "data-scroll": "true",
                        "data-height": "300",
                        "data-mobile-height": "200",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact",
                          attrs: { "data-v-9bb6322a": "" },
                        },
                        [
                          _c(
                            "form",
                            {
                              staticClass: "kt-quick-search__form",
                              attrs: { "data-v-9bb6322a": "", method: "get" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group",
                                  staticStyle: {
                                    background: "transparent !important",
                                  },
                                  attrs: { "data-v-9bb6322a": "" },
                                },
                                [
                                  _vm._m(1),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchSchool,
                                        expression: "searchSchool",
                                      },
                                    ],
                                    staticClass:
                                      "form-control kt-quick-search__input",
                                    staticStyle: {
                                      background: "transparent !important",
                                    },
                                    attrs: {
                                      id: "main-search",
                                      "data-v-9bb6322a": "",
                                      type: "text",
                                      name: "term",
                                      autocomplete: "off",
                                      placeholder: "Search Schools",
                                    },
                                    domProps: { value: _vm.searchSchool },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.searchSchool = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._m(2),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm.filterSchools.length
                        ? _c(
                            "div",
                            _vm._l(_vm.filterSchools, function (school, idx) {
                              return _c(
                                "div",
                                { key: `${idx}_${school.externalSchoolId}` },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "kt-notification__item",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.impersonate(school)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-notification__item-icon",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "course-section-badge-small mr-2",
                                              class: `color${Math.floor(
                                                (school.externalSchoolId.charCodeAt(
                                                  school.externalSchoolId
                                                    .length - 1
                                                ) +
                                                  idx) %
                                                  10
                                              )}`,
                                              staticStyle: {
                                                "min-width": "70px",
                                                display: "inline-block",
                                                width: "auto",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    school.externalSchoolId
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-notification__item-details",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-notification__item-title",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(school.schoolName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-notification__item-time",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(school.district.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "Sorry, no schools match your search criteria."
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b",
        staticStyle: { "background-image": "url(/images/bg-1.jpg)" },
      },
      [
        _c("h3", { staticClass: "kt-head__title" }, [_vm._v(" Admin Menu ")]),
        _c(
          "ul",
          {
            staticClass:
              "nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x",
            attrs: { role: "tablist" },
          },
          [
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link show active",
                  attrs: {
                    "data-toggle": "tab",
                    href: "#topbar_notifications_notifications",
                    role: "tab",
                    "aria-selected": "true",
                  },
                },
                [_vm._v("Schools")]
              ),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "input-group-prepend", attrs: { "data-v-9bb6322a": "" } },
      [
        _c(
          "span",
          { staticClass: "input-group-text", attrs: { "data-v-9bb6322a": "" } },
          [
            _c("i", {
              staticClass: "flaticon2-search-1",
              attrs: { "data-v-9bb6322a": "" },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "input-group-append", attrs: { "data-v-9bb6322a": "" } },
      [
        _c(
          "span",
          { staticClass: "input-group-text", attrs: { "data-v-9bb6322a": "" } },
          [
            _c("i", {
              staticClass: "la la-close kt-quick-search__close",
              attrs: { "data-v-9bb6322a": "" },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }