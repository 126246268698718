var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$_userMixins_isSuperAdmin
    ? _c(
        "div",
        {
          staticClass:
            "kt-header__topbar-item kt-header__topbar-item--search dropdown",
        },
        [
          _c(
            "div",
            {
              staticClass: "kt-header__topbar-wrapper",
              attrs: {
                id: "admin-popover",
                "data-toggle": "dropdown",
                "data-offset": "10px, 0px",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.setSuperMenuButton("toggle")
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "kt-header__topbar-icon" },
                [_c("SVGIcon", { attrs: { name: "superAdmin" } })],
                1
              ),
            ]
          ),
          _c(
            "b-popover",
            {
              attrs: {
                show: _vm.showSuperMenuButton,
                target: "admin-popover",
                triggers: "focus",
                placement: "bottom",
                "custom-class": "popover-class",
              },
              on: {
                "update:show": function ($event) {
                  _vm.showSuperMenuButton = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg show",
                  staticStyle: {
                    width: "400px",
                    position: "absolute",
                    transform: "translate3d(-200px, 0px, 0px)",
                    top: "0px",
                    left: "0px",
                    "will-change": "transform",
                  },
                  style: [
                    _vm.$_userMixins_isHomeUser ? { left: "-100px" } : "",
                  ],
                  attrs: { "x-placement": "bottom-end" },
                },
                [
                  _c("TheMenuSearchBarSchool", {
                    attrs: { schools: _vm.schools },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }