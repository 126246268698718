<template>
<b-modal
    ref="cat-change-modal"
    v-model="showModal"
    size="xl"
    no-close-on-backdrop
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title kt-font-danger">
            {{ extCourseSectionId }} - Grade Category Reassignments
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>

    <template #default>
        <div class="modal-body">
            <div class="form-group form-group-last pt-3 pb-5">
                <div
                    class="alert alert-secondary"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-danger kt-font-brand" />
                    </div>
                    <div class="alert-text">
                        The Grade Template <code>{{ gradeTemplate.gradeTemplateName }}</code> for <code>{{ extCourseSectionId }}</code> has been deleted by an administrator.
                    </div>
                </div>
            </div>
            <div class="form-group row px-5">
                <div class="kt-portlet__content row">
                    <div
                        style="width: 600px; max-width: 400px"
                        class="ml-4"
                    >
                        <GradeTemplateCourseUpdater
                            class="w-100"
                            :original-grade-template-id="gradeTemplate?.gradeTemplateId || null"
                            :course-section-id="course.courseSectionId"
                            :course="course"
                            :index="0"
                        />
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.stop.prevent="ignore"
        >
            Ignore For Now
        </button>
    </template>
</b-modal>
</template>

<script>
import GradeTemplateCourseUpdater from './GradeTemplateCourseUpdater.vue';
import { getPortfolioCacheKey } from '../store/mixins/portfolioMixins';
import courseMixins from '../store/mixins/courseMixins';
import Types from '../store/Types';

export default {
    name: 'GlobalTemplateDeletedModal',
    components: {
        GradeTemplateCourseUpdater,
    },
    mixins: [courseMixins],
    data() {
        return {
            saving: false,
            show: false,
            changes: [],
        };
    },
    computed: {
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        gradeTemplate() {
            const { course } = this;
            if (!course) return null;
            return course.gradeTemplate;
        },
        isPortfolioReady() {
            const cacheKey = getPortfolioCacheKey(this.$route, this.$store.state.database);
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        showModal: {
            get() {
                return this.show;
            },
            set(val) {
                this.show = val;
            },
        },
    },
    watch: {
        $route() {
            this.populate();
        },
        isPortfolioReady() {
            this.populate();
        },
        gradeTemplate() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        ignore() {
            this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, true);
            setTimeout(function () {
                this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, false);
            }, 1000 * 60 * 15); // 15 mins
            this.showModal = false;
        },
        populate() {
            const { schoolEmail, extCourseSectionId } = this.$route.params;
            const isPortfolioView = Boolean(schoolEmail && extCourseSectionId);
            if (!isPortfolioView) return false;
            if (!this.course) return false;

            const { isPortfolioReady } = this;
            if (!isPortfolioReady) return false;

            const { gradeTemplate } = this;
            if (!gradeTemplate) return false;
            this.showModal = gradeTemplate.deleted;
        },
    },
};
</script>
