var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kt-header__topbar-item kt-header__topbar-item--user",
      class: { show: _vm.showUserBar },
    },
    [
      _c(
        "div",
        {
          staticClass: "kt-header__topbar-wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.setUserBar("toggle")
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "kt-header__topbar-user" },
            [
              _c("UserAvatar", { attrs: { "avatar-user": _vm.user } }, [
                !_vm.encryptionEnabled || _vm.$_userMixins_isSchoolUser
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "kt-badge kt-badge--username kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold",
                        staticStyle: { height: "32px", "font-size": "1rem" },
                      },
                      [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                    )
                  : _c(
                      "span",
                      {
                        staticClass:
                          "kt-badge kt-badge--username kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold",
                        staticStyle: { height: "32px", "font-size": "1rem" },
                      },
                      [_c("i", { staticClass: "fa fa-user" })]
                    ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              key: `userBar_${_vm.data.idx}`,
              staticClass:
                "dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl",
              class: { show: _vm.showUserBar },
              style: _vm.styleObject,
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x",
                  staticStyle: { "background-image": "url(/images/bg-1.jpg)" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "kt-user-card__avatar" },
                    [
                      _c(
                        "user-avatar",
                        { attrs: { "avatar-user": _vm.user } },
                        [
                          !_vm.encryptionEnabled ||
                          _vm.$_userMixins_isSchoolUser
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-brand kt-font-light",
                                },
                                [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-brand kt-font-light",
                                },
                                [_c("i", { staticClass: "fa fa-user" })]
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "kt-user-card__name" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.encryptionEnabled ||
                            _vm.$_userMixins_isSchoolUser
                            ? `${_vm.firstName} ${_vm.lastName}`
                            : `${_vm.mappedUserName || _vm.userName}`
                        ) +
                        " "
                    ),
                  ]),
                  _vm.role
                    ? _c("div", { staticClass: "kt-user-card__badge" }, [
                        _c(
                          "span",
                          {
                            staticClass: "btn btn-label-brand btn-font-md",
                            staticStyle: { color: "white" },
                          },
                          [_vm._v(_vm._s(_vm.role))]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                { staticClass: "kt-notification" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-notification__item",
                      attrs: { to: { name: "AccountSessions" } },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "kt-notification__item-icon" },
                        [
                          _c("SVGIcon", {
                            staticStyle: { opacity: "0.8" },
                            attrs: { name: "account" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "kt-notification__item-details" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-notification__item-title kt-font-bold",
                            },
                            [_vm._v(" Account Settings ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "kt-notification__item-time" },
                            [_vm._v(" " + _vm._s(_vm.userName) + " ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  false
                    ? _c(
                        "a",
                        {
                          staticClass: "kt-notification__item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.clearCache.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "kt-notification__item-icon" },
                            [
                              _c("SVGIcon", {
                                staticStyle: { opacity: "0.8" },
                                attrs: { name: "database" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "kt-notification__item-details",
                              attrs: {
                                title: _vm.dbProperties
                                  ? _vm.dbProperties.timestamp
                                  : "",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-notification__item-title kt-font-bold",
                                },
                                [_vm._v(" Reset Data Cache ")]
                              ),
                              _vm.dbProperties
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "kt-notification__item-time",
                                    },
                                    [
                                      _vm._v(
                                        " Created " +
                                          _vm._s(
                                            _vm.getRelativeDate(
                                              _vm.dbProperties
                                                ? _vm.dbProperties.timestamp
                                                : ""
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "kt-notification__item",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.showTermPicker()
                        },
                      },
                    },
                    [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "kt-notification__item-details" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-notification__item-title kt-font-bold",
                            },
                            [_vm._v(_vm._s(_vm.schoolName))]
                          ),
                          _c(
                            "div",
                            { staticClass: "kt-notification__item-time" },
                            [_vm._v(_vm._s(_vm.schoolTermName))]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm.isBugReporter
                    ? _c(
                        "router-link",
                        {
                          staticClass: "kt-notification__item",
                          attrs: { to: { name: "CreateBugReport" } },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "kt-notification__item-icon" },
                            [
                              _c("i", {
                                staticClass: "la la-bug kt-font-danger",
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "kt-notification__item-details" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-notification__item-title kt-font-bold",
                                },
                                [_vm._v(" Submit an issue ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "kt-notification__item-time" },
                                [
                                  _vm._v(
                                    " Request a feature or submit an issue. "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.user.school.schoolTermId > 0 &&
                  !["Student", "Guardian"].includes(_vm.$_userMixins_userRole)
                    ? _c(
                        "a",
                        {
                          staticClass: "kt-notification__item",
                          attrs: {
                            target: "_blank",
                            href: `/api/schools/${_vm.user.school.schoolId}/terms/${_vm.user.school.schoolTermId}/feedback/redirect`,
                          },
                        },
                        [_vm._m(1), _vm._m(2)]
                      )
                    : _vm._e(),
                  !_vm.$_userMixins_isHomeUser || _vm.$_userMixins_isSuperAdmin
                    ? _c(
                        "a",
                        {
                          staticClass: "kt-notification__item",
                          attrs: { href: _vm.wikiUrl, target: "_blank" },
                        },
                        [_vm._m(3), _vm._m(4)]
                      )
                    : _vm._e(),
                  !_vm.$_userMixins_isHomeUser || _vm.$_userMixins_isSuperAdmin
                    ? _c(
                        "a",
                        {
                          staticClass: "kt-notification__item",
                          attrs: {
                            href: "https://syncgrades.com/support",
                            target: "_blank",
                          },
                        },
                        [
                          _vm._m(5),
                          _c(
                            "div",
                            { staticClass: "kt-notification__item-details" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-notification__item-title kt-font-bold",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("components.theUserBar.help"))
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "kt-notification__item-time" },
                                [_vm._v("Submit your inquiry here")]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "kt-notification__custom kt-space-between pull-right",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "kt-badge kt-badge--danger kt-badge--inline kt-badge--md kt-badge--rounded sg-sign-out",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.logOut()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-sign-out-alt mr-2" }),
                          _vm._v(" Sign Out "),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("b-modal", {
        ref: "password-modal",
        attrs: {
          size: "lg",
          variant: "primary",
          centered: "",
          title: "Change Your Password",
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function () {
              return [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("components.theUserBar.changePassword")) +
                      " "
                  ),
                ]),
                _c("button", {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.resetPasswordModal()
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("components.theUserBar.oldPassword")) +
                        ": "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.password.old,
                          expression: "data.password.old",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "currentPassword", type: "password" },
                      domProps: { value: _vm.data.password.old },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.password,
                            "old",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("components.theUserBar.newPassword")) +
                        ": "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.password.new,
                          expression: "data.password.new",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "oldPassword", type: "password" },
                      domProps: { value: _vm.data.password.new },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.password,
                            "new",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("components.theUserBar.retypePassword")) +
                        ": "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.password.again,
                          expression: "data.password.again",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "againPassword", type: "password" },
                      domProps: { value: _vm.data.password.again },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.password,
                            "again",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.theUserBar.passwordRequirements.title"
                          )
                        ) + ":"
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.theUserBar.passwordRequirements.length"
                            )
                          )
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.theUserBar.passwordRequirements.uppercase"
                            )
                          )
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.theUserBar.passwordRequirements.digits"
                            )
                          )
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.theUserBar.passwordRequirements.special"
                            )
                          )
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.theUserBar.passwordRequirements.dictionary"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { width: "100%" } },
                  [
                    _c("div", { staticClass: "col-10" }, [
                      _c("span", { staticClass: "kt-font-danger" }, [
                        _vm._v(_vm._s(_vm.data.password.message)),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary pull-right",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmPassword()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("generic.labels.confirm")) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("b-modal", {
        ref: "term-picker-modal",
        attrs: { variant: "primary" },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function ({ close }) {
              return [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("components.theUserBar.selectTerm")) +
                      " "
                  ),
                ]),
                _c("button", {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return close()
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "form-group row" },
                  _vm._l(_vm.sortedSchoolTerms, function (term, i) {
                    return _c(
                      "div",
                      {
                        key: "term" + i,
                        staticClass: "kt-notification-v2",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "kt-notification-v2__item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectTerm(term.schoolTermId)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "kt-notification-v2__item-icon" },
                              [
                                _c("i", {
                                  class: {
                                    "kt-font-success": i == _vm.user.termIndex,
                                    "kt-font-dark": !i == _vm.user.termIndex,
                                    "flaticon2-calendar-3": !term.hasCourses,
                                    "flaticon-presentation": term.hasCourses,
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "kt-notification-v2__itek-wrapper",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kt-notification-v2__item-title",
                                    class: {
                                      "kt-font-primary":
                                        i == _vm.user.termIndex,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(term.schoolName) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kt-notification-v2__item-desc kt-font-bolder",
                                  },
                                  [
                                    _vm._v(" " + _vm._s(term.name) + " "),
                                    term.current
                                      ? _c("span", [
                                          _vm._v(
                                            " - " +
                                              _vm._s(
                                                _vm
                                                  .$t(
                                                    "components.theUserBar.current"
                                                  )
                                                  .toUpperCase()
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-notification__item-icon" }, [
      _c("i", { staticClass: "flaticon2-calendar-3 kt-font-success" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-notification__item-icon" }, [
      _c("i", { staticClass: "fa fa-vote-yea kt-font-brand" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-notification__item-details" }, [
      _c("div", { staticClass: "kt-notification__item-title kt-font-bold" }, [
        _vm._v(" Community Portal "),
      ]),
      _c("div", { staticClass: "kt-notification__item-time" }, [
        _vm._v(" Request features "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-notification__item-icon" }, [
      _c("i", { staticClass: "flaticon2-document kt-font-primary" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-notification__item-details" }, [
      _c("div", { staticClass: "kt-notification__item-title kt-font-bold" }, [
        _vm._v("Wiki"),
      ]),
      _c("div", { staticClass: "kt-notification__item-time" }, [
        _vm._v("User Documentation"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-notification__item-icon" }, [
      _c("i", { staticClass: "flaticon2-mail kt-font-primary" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }