<template>
<div v-if="ready">
    <SchoolPasswordModal />
    <GlobalTemplateDeletedModal />
    <GlobalTemplateCategoryChangeModal />
    <GlobalComboCourseGradeTemplateModal />
    <GlobalComboCourseGoogleCourseModal />
    <GlobalTemplateGoogleCategoryModal v-if="!isGoogleDisabled" />
</div>
</template>

<script>
import { mapState } from 'vuex';
import GlobalTemplateDeletedModal from './GlobalTemplateDeletedModal.vue';
import GlobalTemplateCategoryChangeModal from './GlobalTemplateCategoryChangeModal.vue';
import GlobalTemplateGoogleCategoryModal from './GlobalTemplateGoogleCategoryModal.vue';
import GlobalComboCourseGradeTemplateModal from './GlobalComboCourseGradeTemplateModal.vue';
import GlobalComboCourseGoogleCourseModal from './GlobalComboCourseGoogleCourseModal.vue';
import SchoolPasswordModal from '../layouts/TheRouterLayout/components/SchoolPasswordModal.vue';

import { getPortfolioCacheKey } from '../store/mixins/portfolioMixins';

export default {
    name: 'GlobalModals',
    components: {
        SchoolPasswordModal,
        GlobalTemplateDeletedModal,
        GlobalTemplateCategoryChangeModal,
        GlobalTemplateGoogleCategoryModal,
        GlobalComboCourseGoogleCourseModal,
        GlobalComboCourseGradeTemplateModal,
    },
    computed: {
        ...mapState({
            school: (state) => state.user.school,
        }),
        isGoogleDisabled() {
            const { school } = this;
            return ['District Managed', 'Disabled'].includes(school.googleSetting);
        },
        ignoreWarnings() {
            return this.$store.state.ignoreWarnings;
        },
        globalModalShow() {
            return this.$store.state.globalModal.show;
        },
        ready() {
            const { schoolEmail, extCourseSectionId } = this.$route.params;
            const isPortfolioView = Boolean(schoolEmail && extCourseSectionId);
            if (!isPortfolioView) return;

            const { isPortfolioReady } = this;
            if (!isPortfolioReady) return false;

            if (!isPortfolioReady) return false;

            const { ignoreWarnings, globalModalShow } = this;
            if (globalModalShow) return true;
            if (ignoreWarnings) return false;

            return isPortfolioReady && isPortfolioView;
        },
        isPortfolioReady() {
            const cacheKey = getPortfolioCacheKey(this.$route, this.$store.state.database);
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
    },
};
</script>
