<template>
<div>
    <form>
        <div v-if="!searchSchoolMenu">
            <!--begin: Head -->
            <div
                class="kt-head kt-head--skin-dark"
                style="background-image: url(/images/bg-1.jpg)"
            >
                <h3 class="kt-head__title">
                    Admin Menu<span class="kt-space-15" />
                    <span
                        class="btn btn-success btn-sm btn-bold btn-font-md"
                        @click="searchSchools"
                    >Search Schools</span>
                </h3>
            </div>

            <!--end: Head -->

            <!--begin: Grid Nav -->
            <div class="kt-grid-nav kt-grid-nav--skin-light">
                <div class="kt-grid-nav__row">
                    <router-link
                        :to="{ name: 'CreateOrEditSchool', params: { externalSchoolId: 'new' } }"
                        class="kt-grid-nav__item"
                        @click.native="searchSchools"
                    >
                        <span class="kt-grid-nav__icon">
                            <SVGIcon
                                name="createAssignment"
                                hex-color="#fd7e14"
                            />
                        </span>
                        <span class="kt-grid-nav__title">New School</span>
                    </router-link>
                    <router-link
                        :to="{ name: 'SuperSchools' }"
                        class="kt-grid-nav__item"
                        @click.native="searchSchools"
                    >
                        <span class="kt-grid-nav__icon">

                            <SVGIcon
                                name="superAdmin"
                                hex-color="#17a2b8"
                            />
                        </span>
                        <span class="kt-grid-nav__title">School List</span>
                    </router-link>
                    <router-link
                        :to="{ name: 'ImpersonateUsers' }"
                        class="kt-grid-nav__item"
                        @click.native="searchSchools"
                    >
                        <span class="kt-grid-nav__icon">
                            <SVGIcon
                                name="impersonate"
                                hex-color="#6610f2"
                            />
                        </span>
                        <span class="kt-grid-nav__title">Impersonate</span>
                    </router-link>
                </div>
                <div class="kt-grid-nav__row">
                    <router-link
                        :to="{ name: 'PublicUploads' }"
                        class="kt-grid-nav__item"
                        @click.native="searchSchools"
                    >
                        <span class="kt-grid-nav__icon">
                            <SVGIcon
                                name="folder"
                            />
                        </span>
                        <span class="kt-grid-nav__title">Public Uploads</span>
                    </router-link>
                    <router-link
                        :to="{ name: 'ManageAWS' }"
                        class="kt-grid-nav__item"
                        @click.native="searchSchools"
                    >
                        <span class="kt-grid-nav__icon">
                            <SVGIcon
                                name="AWS"
                                class="kt-svg-icon"
                            />
                        </span>
                        <span class="kt-grid-nav__title">Cloud</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-else>
            <div
                class="kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b"
                style="background-image: url(/images/bg-1.jpg)"
            >
                <h3 class="kt-head__title">
                    Admin Menu
                </h3>
                <ul
                    class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x"
                    role="tablist"
                >
                    <li class="nav-item">
                        <a
                            class="nav-link show active"
                            data-toggle="tab"
                            href="#topbar_notifications_notifications"
                            role="tab"
                            aria-selected="true"
                        >Schools</a>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <div
                    id="topbar_notifications_notifications"
                    class="tab-pane show active"
                    role="tabpanel"
                >
                    <div
                        class="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll ps ps--active-y"
                        data-scroll="true"
                        data-height="300"
                        data-mobile-height="200"
                        style="height: 300px; overflow: hidden;"
                    >
                        <div
                            data-v-9bb6322a=""
                            class="kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact"
                        >
                            <form
                                data-v-9bb6322a=""
                                method="get"
                                class="kt-quick-search__form"
                            >
                                <div
                                    data-v-9bb6322a=""
                                    class="input-group"
                                    style="background: transparent !important;"
                                >
                                    <div
                                        data-v-9bb6322a=""
                                        class="input-group-prepend"
                                    >
                                        <span
                                            data-v-9bb6322a=""
                                            class="input-group-text"
                                        ><i
                                            data-v-9bb6322a=""
                                            class="flaticon2-search-1"
                                        /></span>
                                    </div><input
                                        id="main-search"
                                        v-model="searchSchool"
                                        data-v-9bb6322a=""
                                        type="text"
                                        name="term"
                                        autocomplete="off"
                                        class="form-control kt-quick-search__input"
                                        style="background: transparent !important;"
                                        placeholder="Search Schools"
                                    ><div
                                        data-v-9bb6322a=""
                                        class="input-group-append"
                                    >
                                        <span
                                            data-v-9bb6322a=""
                                            class="input-group-text"
                                        ><i
                                            data-v-9bb6322a=""
                                            class="la la-close kt-quick-search__close"
                                        /></span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Include code here -->
                        <div v-if="filterSchools.length">
                            <div
                                v-for="(school, idx) in filterSchools"
                                :key="`${idx}_${school.externalSchoolId}`"
                            >
                                <div
                                    class="kt-notification__item"
                                    @click.stop.prevent="impersonate(school)"
                                >
                                    <div class="kt-notification__item-icon">
                                        <span
                                            class="course-section-badge-small mr-2"
                                            :class="`color${Math.floor((school.externalSchoolId.charCodeAt(school.externalSchoolId.length - 1) + idx) % 10)}`"
                                            style="min-width: 70px; display: inline-block; width: auto;"
                                        >
                                            {{ school.externalSchoolId }}
                                        </span>
                                    </div>
                                    <div class="kt-notification__item-details">
                                        <div class="kt-notification__item-title">
                                            {{ school.schoolName }}
                                        </div>
                                        <div class="kt-notification__item-time">
                                            {{ school.district.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            class="kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact"
                        >
                            {{ 'Sorry, no schools match your search criteria.' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</template>
<script lang="ts">
import { mapMutations } from 'vuex';
import Types from '../../../../store/Types';

export default {
    name: 'TheMenuSearchBarSchool',
    props: {
        schools: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            searchSchoolMenu: false,
        };
    },
    computed: {
        searchSchool: {
            get() {
                return this.$store.state.search.searchSchool;
            },
            set(value) {
                this.setSearchSchool(value);
            },
        },
        filterSchools() {
            const searchSchool = this.searchSchool.toLowerCase();
            const output = this.schools.filter((school) => {
                const {
                    externalSchoolId, schoolName, district, author,
                } = school;
                const { name: districtName } = district;
                const search = `${externalSchoolId} ${schoolName} ${districtName || ''} ${author}`;
                return search.toLowerCase().includes(searchSchool);
            });
            return output.slice(0, 3);
        },
    },

    mounted() {
        this.setSearchSchool('');
    },
    methods: {
        ...mapMutations(['setSearchSchool']),
        searchSchools() {
            this.searchSchoolMenu = !this.searchSchoolMenu;
        },
        impersonate(school) {
            this.$store.dispatch(Types.actions.IMPERSONATE_PRIMARY, school);
        },
    },
};
</script>
