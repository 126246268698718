var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "cat-change-modal",
    attrs: { size: "xl", "no-close-on-backdrop": "", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title kt-font-danger" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.extCourseSectionId) +
                  " - Grade Category Reassignments "
              ),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                { staticClass: "form-group form-group-last pt-3 pb-5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "alert alert-secondary",
                      attrs: { role: "alert" },
                    },
                    [
                      _c("div", { staticClass: "alert-icon" }, [
                        _c("i", {
                          staticClass: "flaticon-danger kt-font-brand",
                        }),
                      ]),
                      _c("div", { staticClass: "alert-text" }, [
                        _vm._v(" The Grade Template "),
                        _c("code", [
                          _vm._v(_vm._s(_vm.gradeTemplate.gradeTemplateName)),
                        ]),
                        _vm._v(" for "),
                        _c("code", [_vm._v(_vm._s(_vm.extCourseSectionId))]),
                        _vm._v(" has been deleted by an administrator. "),
                      ]),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "form-group row px-5" }, [
                _c("div", { staticClass: "kt-portlet__content row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ml-4",
                      staticStyle: { width: "600px", "max-width": "400px" },
                    },
                    [
                      _c("GradeTemplateCourseUpdater", {
                        staticClass: "w-100",
                        attrs: {
                          "original-grade-template-id":
                            _vm.gradeTemplate?.gradeTemplateId || null,
                          "course-section-id": _vm.course.courseSectionId,
                          course: _vm.course,
                          index: 0,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.ignore.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Ignore For Now ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }