<template>
<div
    v-if="$_userMixins_isSuperAdmin"
    class="kt-header__topbar-item kt-header__topbar-item--search dropdown"
>
    <div
        id="admin-popover"
        class="kt-header__topbar-wrapper"
        data-toggle="dropdown"
        data-offset="10px, 0px"
        @click.stop.prevent="setSuperMenuButton('toggle')"
    >
        <span class="kt-header__topbar-icon">
            <SVGIcon :name="'superAdmin'" />
        </span>
    </div>

    <b-popover
        :show.sync="showSuperMenuButton"
        target="admin-popover"
        triggers="focus"
        placement="bottom"
        custom-class="popover-class"
    >
        <div
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg show"
            style="width: 400px; position: absolute; transform: translate3d(-200px, 0px, 0px); top: 0px; left: 0px; will-change: transform;"
            :style="[$_userMixins_isHomeUser ? {left: '-100px'} : '']"
            x-placement="bottom-end"
        >
            <TheMenuSearchBarSchool :schools="schools" />
        </div>
    </b-popover>
</div>
</template>

<script lang="ts">
import { mapMutations } from 'vuex';
import userMixins from '../../../../store/mixins/userMixins';
import TheMenuSearchBarSchool from './TheMenuSearchBarSchool.vue';

export default {
    name: 'TheSuperMenuButton',
    components: { TheMenuSearchBarSchool },
    mixins: [userMixins],
    computed: {
        showSuperMenuButton: {
            get() {
                return this.$store.state.navigation.showSuperMenuButton;
            },
            set() {},
        },
        schools() {
            return this.$store.state.database.schools;
        },
    },
    methods: {
        ...mapMutations([
            'setSuperMenuButton',
        ]),
    },
};
</script>

<style scoped>
likt-menu__item:hover span.kt-menu__link-text {
    color: #0f88ef !important;
}
a.kt-menu__link {
    line-height: 1.5;
    font-size: 13px!important;
    font-weight: 300;
    font-family: Poppins,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-box-direction: normal;
    list-style: none!important;
    box-sizing: border-box;
    vertical-align: middle;
    outline: none!important;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    text-decoration: none;
    position: relative;
    transition: all .3s ease;
    cursor: pointer;
    padding: 11px 11px;
    background-color: transparent;
}

li.kt-menu__item {
    font-size: 13px!important;
    font-weight: 300;
    font-family: Poppins,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #646c9a;
    -webkit-box-direction: normal;
    list-style: none!important;
    box-sizing: border-box;
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin: 0;
    padding: 0;
}
span.kt-menu__link-text {
    line-height: 1.5;
    font-family: Poppins,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-box-direction: normal;
    list-style: none!important;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    text-transform: none;
    color: #5f6281;
}
.kt-menu__link-bullet {
    font-size: 13px!important;
    font-weight: 300;
    font-family: Poppins,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-box-direction: normal;
    list-style: none!important;
    color: #0f88ef;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    line-height: 0;
    width: 20px;
}
.kt-menu__submenu {
    width: 260px;
}
.popover-shown *.d-block {
    color: #fff !important;
}
</style>
