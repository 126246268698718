var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "div",
        [
          _c("SchoolPasswordModal"),
          _c("GlobalTemplateDeletedModal"),
          _c("GlobalTemplateCategoryChangeModal"),
          _c("GlobalComboCourseGradeTemplateModal"),
          _c("GlobalComboCourseGoogleCourseModal"),
          !_vm.isGoogleDisabled
            ? _c("GlobalTemplateGoogleCategoryModal")
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }